.element{
    width: 100%;
}
.carousel{
    width: 400px;
    height: 600px;
}

.carouselItem{
    align-items: center;
    justify-content: center;
    width: 200px;
   
  
}
.itemImage{
  justify-content: center;
}
.textItem{
    text-align: center;
    font-family: 'Poppins', Courier, monospace;
    width: 200px;
    font-weight: bold;
}

.textStep{
    color:#FFF;
    text-align: center;
    font-family: 'Poppins', Courier, monospace;
    font-weight: bold;
   
}
.textStepContain{
    width: 50px; 
    height: 50px; 
    justify-content: center;
    background-color: #007bff; 
    border-radius: 50%;
    align-items: center;
    
}
.principal{
    width: 200px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 10px;
}