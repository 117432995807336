.header{
    display: flex;
    justify-content: start;
    margin-bottom: 60px;
    
    
}
.buttonAndroid{
    padding: 10px;
    background-color: green;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.downloadText{
    color:#FFF;
    font-size:large;
    font-weight: bold;
    font-family: 'Poppins', Courier, monospace;
    display: block;
    text-align: center;
    text-decoration: none;
}
.downloadTextTitle{
    color:#000;
    font-size:large;
    width: 100%;
    font-weight:bolder;
    font-family: 'Poppins', Courier, monospace;
    text-align: center;
}

.simple{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    
}
.img{
    width: 500px;
}

@media (min-width: 1024px) {
    .downloadTextTitle{
        color:#000;
        font-size:large;
        font-weight:bolder;
        font-family: 'Poppins', Courier, monospace;
        text-align: center;
    }

 
}

@media (max-width: 768px) {
    .simple {
        padding: 0 20px; /* Ajouter un padding sur les côtés pour éviter que les éléments ne soient pas trop serrés sur les petits écrans */
    }
    .img{
        width: 300px;
    }
}
