.installationComponent{
    border-style: dotted;
    border-color: rgb(211, 202, 202);
    padding: 16px;
    display: flex;
    margin: 20px;
    
}
.titleInstall{
    font-weight:normal;
    font-family: 'Poppins', Courier, monospace;
    font-size: small;
    
}
.iconCheck{
    width: 200;
    height: auto;
}
.installImage{
    
}

